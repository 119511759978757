<template>
  <footer id="footer">
    <p>Monte seu Hambúrguer &copy; Armando | 2024</p>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
#footer {
height: 200px;
background-color: #F4A460;
border-top: 4px solid #D2691E;
color: #F5DEB3;
font-weight: bold;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
}
</style>
